<template>
  <b-overlay
      :show="overlay"
      rounded="sm"
  >
    <b-row>
      <b-col cols="12">

        <b-card v-if="productsList">
          <h3 class="text-primary mb-2">
            Price history
          </h3>
          <b-table
              class="position-relative"
              :items="productsList.priceHistory"
              responsive
              :fields="myTableColumns"
              primary-key="id"
              show-empty
              bordered
              striped
              empty-text="No Products were found"
          >

            <template #cell(createAt)="data">
              {{ new Date(data.item.createAt).toLocaleDateString("en-US") }}
            </template>

          </b-table>
        </b-card>

        <b-row>
          <b-col cols="12">
            <b-card class="col-12">
              <b-row>
                <h4 class="text-primary col-12">New Price</h4>
                <b-col cols="12" md="4">
                  <b-form-group
                      class="mb-2"
                      label="Price"
                  >
                    <b-form-input
                        v-model="newPrice"
                    ></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-button
                      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                      class="col-12 col-md-2"
                      variant="primary"
                      @click="updatePrice"
                  >
                    Save
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>

        </b-row>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import {
  BAlert,
  BAvatar,
  BButton,
  BCard,
  BCardBody,
  BCardText,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BImg,
  BLink,
  BMedia,
  BModal,
  BOverlay,
  BPagination,
  BRow,
  BTab,
  BTable,
  BTabs
} from 'bootstrap-vue'
import vSelect from "vue-select";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import Helper from "@/libs/Helper";
import {required} from '@validations';
import {CategoryPropertyGroupGetBy, ProductCategoriesGetSubCategories,} from "@/libs/Api/ProductCategory";
import LanguagePicker from "@/views/components/utils/LanguagePicker.vue";
import AddEditDeleteButton from "@/views/components/utils/AddEditDeleteButton.vue";
import {GetAllLangs} from "@/libs/Api/Language";
import {ShipmentGetAll} from "@/libs/Api/Shipment";
import ckEditorMain from "@/views/components/ckEditorMain.vue";
import ProductCategoryProperty from "@/views/apps/product/ProductCategoryProperty.vue";
import {
  ProductCreate,
  ProductGetAll,
  ProductGetBy,
  ProductGetById,
  ProductUpdatePrice,
  ProductUpdatePriceByMainId
} from "@/libs/Api/Product";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {UnitsGetAll} from "@/libs/Api/Units";
import {CurrenciesCreate, CurrenciesGetAll} from "@/libs/Api/Currencies";
import {OrderCreateByAdmin} from "@/libs/Api/Order";

export default {
  title: 'Product Price',
  data() {
    return {
      required,
      baseUrl:Helper.baseUrl,
      languageList: [],
      overlay: false,
      categories: [],
      productsList: null,
      count: 10,
      pageNumber: 1,
      totalCount: null,
      newPrice:null,
      myTableColumns: [
        {
          key: "price",
          label: "Price",
        },
        {
          key: "createAt",
          label: "Date",
        },
      ],
      units: [],
      currencies: [],
      order:{
        userId: this.$route.params.userId,
        currencyId: 1,
        orderItems: []
      },
    }
  },
  async created() {
    await this.getAllProductPrice();
  },
  methods: {
    async getAllProductPrice() {
      try {
        this.overlay = true;
        let _this = this;
        let qParams = {
          id:_this.$route.params.id
        };
        let productGetBy = new ProductGetById(_this);
        productGetBy.setParams(qParams);
        await productGetBy.fetch((response) => {
          if (response.isSuccess) {
            _this.productsList = response.data;
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.overlay = false;
      }
    },
    async updatePrice() {
      let _this = this;
      _this.overlay = true;

      let data = {
        mainId:_this.$route.params.mainId,
        price:_this.newPrice
      }

      let productUpdatePrice = new ProductUpdatePriceByMainId(_this);
      productUpdatePrice.setParams(data);
      await productUpdatePrice.fetch((content) => {
        _this.overlay = false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `Operation successful`,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        _this.getAllProductPrice();
      }, (error) => {
        _this.overlay = false;
        console.log(error)
      });
    },
  },
  components: {
    ckEditorMain,
    AddEditDeleteButton,
    LanguagePicker,
    BModal,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,
    BTab,
    BTabs,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BMedia,
    BAvatar,
    BOverlay,
    vSelect,
    BTable,
    BPagination,
    ValidationObserver, ValidationProvider,
    ProductCategoryProperty,
  },
}
</script>

<style>
.modal-header > .close {
  display: none !important;
}
</style>